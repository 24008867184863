
			[data-rs-theme="darkmatter"] {
					--rs-font-family-title: var(--dm-font-family), sans-serif;
--rs-font-family-body: var(--dm-font-family), sans-serif;
--rs-font-family-mono: var(--dm-font-family-mono), monospace;
--rs-font-weight-regular: 400;
--rs-font-weight-medium: 500;
--rs-font-weight-semibold: 600;
--rs-font-weight-bold: 640;
--rs-font-weight-heavy: 800;
--rs-font-weight-black: 900;
--rs-font-size-title-1: 32px;
--rs-line-height-title-1: 40px;
--rs-font-family-title-1: var(--rs-font-family-body);
--rs-font-weight-title-1: var(--rs-font-weight-bold);
--rs-font-size-title-2: 24px;
--rs-line-height-title-2: 32px;
--rs-font-family-title-2: var(--rs-font-family-body);
--rs-font-weight-title-2: var(--rs-font-weight-bold);
--rs-font-size-title-3: 20px;
--rs-line-height-title-3: 28px;
--rs-font-family-title-3: var(--rs-font-family-body);
--rs-font-weight-title-3: var(--rs-font-weight-bold);
--rs-font-size-title-4: 16px;
--rs-line-height-title-4: 24px;
--rs-font-family-title-4: var(--rs-font-family-body);
--rs-font-weight-title-4: var(--rs-font-weight-bold);
--rs-font-size-title-5: 14px;
--rs-line-height-title-5: 20px;
--rs-font-family-title-5: var(--rs-font-family-body);
--rs-font-weight-title-5: var(--rs-font-weight-bold);
--rs-font-size-title-6: 12px;
--rs-line-height-title-6: 16px;
--rs-font-family-title-6: var(--rs-font-family-body);
--rs-font-weight-title-6: var(--rs-font-weight-bold);
--rs-font-size-featured-1: 32px;
--rs-line-height-featured-1: 40px;
--rs-font-family-featured-1: var(--rs-font-family-body);
--rs-font-size-featured-2: 24px;
--rs-line-height-featured-2: 32px;
--rs-font-family-featured-2: var(--rs-font-family-body);
--rs-font-size-featured-3: 20px;
--rs-line-height-featured-3: 28px;
--rs-font-family-featured-3: var(--rs-font-family-body);
--rs-font-size-body-1: 16px;
--rs-line-height-body-1: 24px;
--rs-font-family-body-1: var(--rs-font-family-body);
--rs-font-weight-body-1: var(--rs-font-weight-regular);
--rs-font-size-body-2: 14px;
--rs-line-height-body-2: 20px;
--rs-font-family-body-2: var(--rs-font-family-body);
--rs-font-weight-body-2: var(--rs-font-weight-regular);
--rs-font-size-body-3: 12px;
--rs-line-height-body-3: 16px;
--rs-font-family-body-3: var(--rs-font-family-body);
--rs-font-weight-body-3: var(--rs-font-weight-regular);
--rs-font-size-caption-1: 12px;
--rs-line-height-caption-1: 16px;
--rs-font-family-caption-1: var(--rs-font-family-body);
--rs-font-size-caption-2: 10px;
--rs-line-height-caption-2: 12px;
--rs-font-family-caption-2: var(--rs-font-family-body);
--rs-font-size-docs-title-1: 28px;
--rs-line-height-docs-title-1: 36px;
--rs-font-family-docs-title-1: var(--rs-font-family-body);
--rs-font-weight-docs-title-1: var(--rs-font-weight-bold);
--rs-font-size-docs-title-2: 24px;
--rs-line-height-docs-title-2: 32px;
--rs-font-family-docs-title-2: var(--rs-font-family-body);
--rs-font-weight-docs-title-2: var(--rs-font-weight-bold);
--rs-font-size-docs-title-3: 20px;
--rs-line-height-docs-title-3: 28px;
--rs-font-family-docs-title-3: var(--rs-font-family-body);
--rs-font-weight-docs-title-3: var(--rs-font-weight-bold);
--rs-font-size-docs-title-4: 18px;
--rs-line-height-docs-title-4: 24px;
--rs-font-family-docs-title-4: var(--rs-font-family-body);
--rs-font-weight-docs-title-4: var(--rs-font-weight-bold);
--rs-font-size-docs-title-5: 16px;
--rs-line-height-docs-title-5: 20px;
--rs-font-family-docs-title-5: var(--rs-font-family-body);
--rs-font-weight-docs-title-5: var(--rs-font-weight-bold);
--rs-font-size-docs-quote: 18px;
--rs-line-height-docs-quote: 28px;
--rs-font-family-docs-quote: var(--rs-font-family-body);
--rs-font-weight-docs-quote: var(--rs-font-weight-regular);
--rs-font-size-docs-title-description: 18px;
--rs-line-height-docs-title-description: 24px;
--rs-font-family-docs-title-description: var(--rs-font-family-body);
--rs-font-weight-docs-title-description: var(--rs-font-weight-regular);
--rs-font-size-docs-body: 16px;
--rs-line-height-docs-body: 24px;
--rs-font-family-docs-body: var(--rs-font-family-body);
--rs-font-weight-docs-body: var(--rs-font-weight-regular);
--rs-font-size-docs-body-medium: 14px;
--rs-line-height-docs-body-medium: 20px;
--rs-font-family-docs-body-medium: var(--rs-font-family-body);
--rs-font-weight-docs-body-medium: var(--rs-font-weight-regular);
--rs-font-size-docs-body-small: 12px;
--rs-line-height-docs-body-small: 16px;
--rs-font-family-docs-body-small: var(--rs-font-family-body);
--rs-font-weight-docs-body-small: var(--rs-font-weight-regular);
--rs-unit-base: 4px;
--rs-unit-radius-small: 3px;
--rs-unit-radius-medium: 7px;
--rs-unit-radius-large: 12px;
--rs-unit-radius: 5px;
--rs-unit-x1: 4px;
--rs-unit-x2: 8px;
--rs-unit-x3: 12px;
--rs-unit-x4: 16px;
--rs-unit-x5: 20px;
--rs-unit-x6: 24px;
--rs-unit-x7: 28px;
--rs-unit-x8: 32px;
--rs-unit-x9: 36px;
--rs-unit-x10: 40px;
--rs-color-background-primary: #104FC6;
--rs-color-background-primary-highlighted: #004EE1;
--rs-color-border-primary: #004EE1;
--rs-color-border-primary-faded: #D0E2FF;
--rs-color-background-critical: #E22C2C;
--rs-color-background-critical-faded: #FEF1F2;
--rs-color-background-critical-highlighted: #CB101D;
--rs-color-border-critical: #CB101D;
--rs-color-border-critical-faded: #FBD5D8;
--rs-color-foreground-critical: #CB101D;
--rs-color-background-positive: #118850;
--rs-color-background-positive-faded: #EBFEF6;
--rs-color-background-positive-highlighted: #05751F;
--rs-color-border-positive: #05751F;
--rs-color-border-positive-faded: #CDEDD5;
--rs-color-foreground-positive: #05751F;
--rs-color-border-neutral: #C7CDDB;
--rs-color-background-disabled-faded: #141A2B05;
--rs-color-border-disabled: #DFE2EA;
--rs-color-white: #FFFFFF;
--rs-color-black: #09122A;
--rs-color-background-upgrade: #874CE6;
--rs-color-background-upgrade-highlighted: #6A2BBF;
--rs-color-background-upgrade-faded: #F5F2FD;
--rs-color-background-warning: #CC4D00;
--rs-color-background-warning-highlighted: #B53C0A;
--rs-color-background-warning-faded: #FEF8EB;
--rs-color-background-blur: #FFFFFFF2;
--rs-color-background-blur-neutral-faded: #F4F5F7F2;
--rs-color-background-blur-neutral-highlighted: #ECEDF0F2;
--rs-color-foreground-upgrade: #874CE6;
--rs-color-foreground-warning: #B53C0A;
--rs-color-foreground-neutral-placeholder: #7F8694;
--rs-color-border-upgrade: #6A2BBF;
--rs-color-border-upgrade-faded: #E3DBFA;
--rs-color-border-warning: #B53C0A;
--rs-color-border-warning-faded: #FBE5C5;
--rs-color-border-focus: #4589FF;
--rs-color-border-focus-faded: #D0E2FF;
--rs-color-border-decorative: #14171F0F;
--rs-color-outline-primary: #004EE1;
--rs-color-background-decorative-blue: #EDF5FF;
--rs-color-foreground-decorative-blue: #0F62FE;
--rs-color-border-decorative-blue: #D0E2FF;
--rs-color-background-decorative-cyan: #D5FBFE;
--rs-color-foreground-decorative-cyan: #0E7490;
--rs-color-border-decorative-cyan: #b6e9ec;
--rs-color-background-decorative-fuchsia: #FCF0FF;
--rs-color-foreground-decorative-fuchsia: #B725CB;
--rs-color-border-decorative-fuchsia: #F3D6F9;
--rs-color-background-decorative-green: #EBFEF6;
--rs-color-foreground-decorative-green: #05751F;
--rs-color-border-decorative-green: #C9E6DA;
--rs-color-background-decorative-grey: #EDEFF3;
--rs-color-foreground-decorative-grey: #4E536A;
--rs-color-border-decorative-grey: #E1E4EB;
--rs-color-background-decorative-light-grey: #F6F7F9;
--rs-color-foreground-decorative-light-grey: #68718D;
--rs-color-border-decorative-light-grey: #E3E4E9;
--rs-color-background-decorative-orange: #FFF2E1;
--rs-color-foreground-decorative-orange: #C2410C;
--rs-color-border-decorative-orange: #F9DBAF;
--rs-color-background-decorative-pink: #FDF0F7;
--rs-color-foreground-decorative-pink: #D12372;
--rs-color-border-decorative-pink: #FAD6EA;
--rs-color-background-decorative-purple: #F5F2FD;
--rs-color-foreground-decorative-purple: #874CE6;
--rs-color-border-decorative-purple: #E4DBFA;
--rs-color-background-decorative-red: #FEF1F2;
--rs-color-foreground-decorative-red: #CB101D;
--rs-color-border-decorative-red: #FCD7DA;
--rs-color-background-decorative-teal: #DFFDF6;
--rs-color-foreground-decorative-teal: #107A71;
--rs-color-border-decorative-teal: #CAE5DF;
--rs-color-background-decorative-yellow: #FFFDE7;
--rs-color-foreground-decorative-yellow: #A16207;
--rs-color-border-decorative-yellow: #EFE991;
--rs-color-on-background-primary: #FFFFFF;
--rs-color-rgb-background-primary: 16, 79, 198;
--rs-color-rgb-background-primary-highlighted: 0, 78, 225;
--rs-color-rgb-border-primary: 0, 78, 225;
--rs-color-rgb-border-primary-faded: 208, 226, 255;
--rs-color-on-background-critical: #FFFFFF;
--rs-color-rgb-background-critical: 226, 44, 44;
--rs-color-rgb-background-critical-faded: 254, 241, 242;
--rs-color-rgb-background-critical-highlighted: 203, 16, 29;
--rs-color-rgb-border-critical: 203, 16, 29;
--rs-color-rgb-border-critical-faded: 251, 213, 216;
--rs-color-on-background-positive: #FFFFFF;
--rs-color-rgb-background-positive: 17, 136, 80;
--rs-color-rgb-background-positive-faded: 235, 254, 246;
--rs-color-rgb-background-positive-highlighted: 5, 117, 31;
--rs-color-rgb-border-positive: 5, 117, 31;
--rs-color-rgb-border-positive-faded: 205, 237, 213;
--rs-color-rgb-border-neutral: 199, 205, 219;
--rs-color-rgb-background-disabled-faded: 20, 26, 43;
--rs-color-rgb-border-disabled: 223, 226, 234;
--rs-color-rgb-white: 255, 255, 255;
--rs-color-rgb-black: 9, 18, 42;
--rs-color-on-background-upgrade: #FFFFFF;
--rs-color-rgb-background-upgrade: 135, 76, 230;
--rs-color-rgb-background-upgrade-highlighted: 106, 43, 191;
--rs-color-rgb-background-upgrade-faded: 245, 242, 253;
--rs-color-rgb-background-warning: 204, 77, 0;
--rs-color-rgb-background-warning-highlighted: 181, 60, 10;
--rs-color-rgb-background-warning-faded: 254, 248, 235;
--rs-color-rgb-background-blur: 255, 255, 255;
--rs-color-rgb-background-blur-neutral-faded: 244, 245, 247;
--rs-color-rgb-background-blur-neutral-highlighted: 236, 237, 240;
--rs-color-rgb-border-upgrade: 106, 43, 191;
--rs-color-rgb-border-upgrade-faded: 227, 219, 250;
--rs-color-rgb-border-warning: 181, 60, 10;
--rs-color-rgb-border-warning-faded: 251, 229, 197;
--rs-color-rgb-border-focus: 69, 137, 255;
--rs-color-rgb-border-focus-faded: 208, 226, 255;
--rs-color-rgb-border-decorative: 20, 23, 31;
--rs-color-rgb-background-decorative-blue: 237, 245, 255;
--rs-color-rgb-border-decorative-blue: 208, 226, 255;
--rs-color-rgb-background-decorative-cyan: 213, 251, 254;
--rs-color-rgb-border-decorative-cyan: 182, 233, 236;
--rs-color-rgb-background-decorative-fuchsia: 252, 240, 255;
--rs-color-rgb-border-decorative-fuchsia: 243, 214, 249;
--rs-color-rgb-background-decorative-green: 235, 254, 246;
--rs-color-rgb-border-decorative-green: 201, 230, 218;
--rs-color-rgb-background-decorative-grey: 237, 239, 243;
--rs-color-rgb-border-decorative-grey: 225, 228, 235;
--rs-color-rgb-background-decorative-light-grey: 246, 247, 249;
--rs-color-rgb-border-decorative-light-grey: 227, 228, 233;
--rs-color-rgb-background-decorative-orange: 255, 242, 225;
--rs-color-rgb-border-decorative-orange: 249, 219, 175;
--rs-color-rgb-background-decorative-pink: 253, 240, 247;
--rs-color-rgb-border-decorative-pink: 250, 214, 234;
--rs-color-rgb-background-decorative-purple: 245, 242, 253;
--rs-color-rgb-border-decorative-purple: 228, 219, 250;
--rs-color-rgb-background-decorative-red: 254, 241, 242;
--rs-color-rgb-border-decorative-red: 252, 215, 218;
--rs-color-rgb-background-decorative-teal: 223, 253, 246;
--rs-color-rgb-border-decorative-teal: 202, 229, 223;
--rs-color-rgb-background-decorative-yellow: 255, 253, 231;
--rs-color-rgb-border-decorative-yellow: 239, 233, 145;
--rs-duration-fast: 200ms;
--rs-duration-medium: 300ms;
--rs-duration-slow: 400ms;
--rs-easing-standard: cubic-bezier(0.4, 0, 0.2, 1);
--rs-easing-accelerate: cubic-bezier(0.4, 0, 1, 1);
--rs-easing-decelerate: cubic-bezier(0, 0, 0.2, 1);
--rs-shadow-raised: 0px 1px 3px -3px rgba(9, 18, 42, 0.1), 0px 1px 3px -1px rgba(9, 18, 42, 0.06);
--rs-shadow-overlay: 0px 5px 10px 0px rgba(9, 18, 42, 0.05), 0px 15px 25px 0px rgba(9, 18, 42, 0.07);
			}

			[data-rs-theme="darkmatter"][data-rs-color-mode="light"] {
					--rs-color-background-primary-faded: #EDF5FF;
--rs-color-foreground-primary: #104FC6;
--rs-color-background-neutral: #182D660A;
--rs-color-background-neutral-faded: #141A2B05;
--rs-color-background-neutral-highlighted: #182D661A;
--rs-color-border-neutral-faded: #ECEEF3;
--rs-color-foreground-neutral: #14171F;
--rs-color-foreground-neutral-faded: #606981;
--rs-color-background-disabled: #182D660A;
--rs-color-foreground-disabled: #c6ccda;
--rs-color-background-elevation-base: #FFFFFF;
--rs-color-background-elevation-raised: #FFFFFF;
--rs-color-background-elevation-overlay: #FFFFFF;
--rs-color-background-page: #FFFFFF;
--rs-color-background-page-faded: #FAFAFA;
--rs-color-rgb-background-primary-faded: 237, 245, 255;
--rs-color-on-background-neutral: #09122A;
--rs-color-rgb-background-neutral: 24, 45, 102;
--rs-color-rgb-background-neutral-faded: 20, 26, 43;
--rs-color-rgb-background-neutral-highlighted: 24, 45, 102;
--rs-color-rgb-border-neutral-faded: 236, 238, 243;
--rs-color-rgb-background-disabled: 24, 45, 102;
--rs-color-rgb-background-elevation-base: 255, 255, 255;
--rs-color-rgb-background-elevation-raised: 255, 255, 255;
--rs-color-rgb-background-elevation-overlay: 255, 255, 255;
--rs-color-rgb-background-page: 255, 255, 255;
--rs-color-rgb-background-page-faded: 250, 250, 250;
			}

			[data-rs-theme="darkmatter"][data-rs-color-mode="dark"] {
					--rs-color-background-primary-faded: #0F62FE0F;
--rs-color-foreground-primary: #8CB4FF;
--rs-color-background-neutral: #D0E2FF0F;
--rs-color-background-neutral-faded: #D0E2FF0A;
--rs-color-background-neutral-highlighted: #D0E2FF14;
--rs-color-border-neutral-faded: #22273A;
--rs-color-foreground-neutral: #FFFFFF;
--rs-color-foreground-neutral-faded: #9DA8BB;
--rs-color-background-disabled: #D0E2FF12;
--rs-color-foreground-disabled: #3f4962;
--rs-color-foreground-warning: #FF9250;
--rs-color-background-elevation-base: #040A1E;
--rs-color-background-elevation-raised: #040A1E;
--rs-color-background-elevation-overlay: #040A1E;
--rs-color-background-page: #040A1E;
--rs-color-background-page-faded: #09122A;
--rs-color-rgb-background-primary-faded: 15, 98, 254;
--rs-color-on-background-neutral: #FFFFFF;
--rs-color-rgb-background-neutral: 208, 226, 255;
--rs-color-rgb-background-neutral-faded: 208, 226, 255;
--rs-color-rgb-background-neutral-highlighted: 208, 226, 255;
--rs-color-rgb-border-neutral-faded: 34, 39, 58;
--rs-color-rgb-background-disabled: 208, 226, 255;
--rs-color-rgb-background-elevation-base: 4, 10, 30;
--rs-color-rgb-background-elevation-raised: 4, 10, 30;
--rs-color-rgb-background-elevation-overlay: 4, 10, 30;
--rs-color-rgb-background-page: 4, 10, 30;
--rs-color-rgb-background-page-faded: 9, 18, 42;
			}
		